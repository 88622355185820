<template>
  <div class="manager">
    <div class="company-detail">
      <div class="company-detail-center w">
        <div class="company-detail-l">
          <img
            :src="urlImg+xq.img"
            alt=""
          />
          <!---->
        </div>
        <div class="company-detail-r">
          <p class="company-name">{{ xq.title }}</p>
          <div class="field-tabs">
            <span class="lf-tab">
              <i class="el-icon-school"></i>
              <span class="tab-text">{{ xq.typetitle }} </span></span
            >
            <span class="lf-tab lf-tab-rg">
              <i class="el-icon-menu"></i>
              <span class="tab-text">{{ xq.job }} </span></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="wrapPolist w">
      <div class="describe">
        <h3 class="describe-name"> </h3>
      </div>

      <div class="each-type">
        <h3 class="type-name">联系方式</h3>
        <p class="type-bottom">
          <span style="display: block">手机号码：{{ xq.phone }}</span>
          <span>电子邮箱：{{ xq.email }}</span>
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name" style="width: 160px">任职公司及职务</h3>
        <p class="type-bottom">
          {{ xq.company_name }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">工作内容</h3>
        <p class="type-bottom">
          {{ xq.job_level }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ManagerXq } from "@/common/js/api";
import { urlImg } from "@/common/js/url";
export default {
  name: "Manager",
  data() {
    return {
      id: "",
      xq: {},
      urlImg:''
    };
  },
  created() {
       this.urlImg = urlImg();
    this.id = this.$route.query.id;
    this.getManagerXq();
  },
  methods: {
    // 技术经理人详情
    getManagerXq() {
      ManagerXq({
        id: this.id,
      })
        .then((res) => {
          this.xq = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
.manager {
  background-color: #f8f9fa;
  padding-bottom: 25px;
  margin-top: 135px;
}
.company-detail {
  background: #f0f8ff;
  padding: 8px 0;
  .company-detail-l {
    width: 162px;
    height: 162px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 48px;
  }
  .company-detail-r {
    width: 900px;
    display: inline-block;
    vertical-align: middle;
    .company-name {
      font-size: 18px;
      color: #101010;
      line-height: 18px;
      margin-bottom: 12px;
      font-weight: 700;
    }
    .field-tabs {
      font-size: 14px;
      .lf-tab {
        margin-right: 10px;
      }
      i {
        color: #3892eb;
        margin-right: 5px;
      }
    }
  }
}
.wrapPolist {
  margin-top: 20px;
  background-color: #fff;
  padding: 50px 23px 50px;
  .describe {
    margin-bottom: 37px;
    .describe-name {
      font-size: 20px;
      font-weight: 700;
      display: inline-block;
      background: #fff;
      color: #3892eb;
      position: relative;
      font-weight: 700;
      padding-right: 10px;
    }
  }

  .describe:before {
    content: "";
    display: block;
    border-top: 1px dotted #3892eb;
    position: relative;
    top: 0.7rem; /*调节线高*/
    left: 0;
  }
  .each-type {
    padding: 22px 0 10px;
    font-size: 14px;
    color: #00050a;
    .type-name {
      color: #3892eb;
      font-size: 18px;
      margin-bottom: 16px;
      border: 1px solid #3892eb;
      width: 102px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border-radius: 5px;
    }
    .type-bottom {
      color: rgba(105, 105, 105, 100);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
      padding-left: 14px;
      padding-bottom: 30px;
      line-height: 1.5;
    }
  }
}
@media screen and (max-width: 1200px) {
	.manager{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.company-detail .company-detail-r .company-name{
		font-size: 1.9rem;
		line-height: 2.5rem;
	}
	.company-detail .company-detail-r{
		flex-grow: 1;
		width: auto;
	}
	.company-detail .company-detail-r .field-tabs{
		font-size: 1.5rem;
	}
	.wrapPolist .each-type .type-name{
		width: 15rem !important;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.6rem;
	}
	.wrapPolist .each-type .type-bottom{
		font-size: 1.4rem;
	}
}
</style>