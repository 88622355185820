<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 技术经理人详情
     -->
    <Manager></Manager>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import Manager from "./base/Manager";
// import Qs from 'qs'
export default {
  name: "manager",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    Manager,
  },
};
</script>

<style scoped>
</style>
